<template>
<section class="py_100">
  <div class="d-flex align-items-center justify-content-center h-100 py_100">
    <div class="page_not_found text-center h-100 w-50 d-none">
        <h1 class="text-danger">SSO Login</h1>
        <h2 class="m-4 text-uppercase">Please Wait! </h2>
        <p> You are going to be authenticated  </p>
        <div class="separator  transparent center  " style="margin-top:35px;"></div>
           
      </div>
  </div>
  </section>
</template>

<script> 
import AuthService from "@/services/AuthService"; 
export default {
  name: "sso",
  mounted(){ 
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    window.localStorage.setItem("userToken",this.$route.params.token);
    this.getAuthUser();     
  },
   methods: { 
    getAuthUser() {
      this.$parent.$refs.fullpageloader.is_full_page_loader = true; 
      AuthService.getAuthUser()
        .then((response) => {                      
            window.localStorage.setItem("employeeEmail",response.data.user.email);
            window.localStorage.setItem("user_profile",response.data.user.avatar);  
            window.localStorage.setItem("companyInformation", JSON.stringify(response.data.client));
            window.localStorage.setItem("loginType",response.data.user.login_type);
            if(response.data.client.company_code.toLowerCase() == 'airbnb' || response.data.client.company_code.toLowerCase() == 'a45')
            {
              window.location.href = window.location.origin+'/airbnb';
            }else {
              window.location.href = window.location.origin+'/user';
            }
            this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch((error) => {
          console.log(error.response.data.error);
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;          
          this.$toast.error(error.response.data.error , {
            position: "top-right",
          });
          this.$router.push({
              name: "login",
            });
        });
    },
  }
};
</script>
